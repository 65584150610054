// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Flatpickr
@import '~flatpickr/dist/flatpickr.min.css';

// Lightbox
@import '~lightbox2/dist/css/lightbox.min.css';


.fc a {
    text-decoration: none;
}

.fc-event {
    cursor: pointer;
}

.fc thead, .fc tbody, .fc tfoot, .fc tr, .fc td, .fc th {
    border: none;
}

.fc-toolbar {
    text-transform: capitalize;
}

.fc-col-header {
    background-color: rgba(155, 155, 155, 0.3);
}